import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/-2t8-d3rq8o">
    <SEO title="How to Live a Happy Life - Greatest Hits" />
  </Layout>
)

export default SermonPost
